.container {
  :global {
    .upgrade-account-content {
      display: flex;
      margin: auto;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      margin-top: 12px;
      .upgrade-account {
        cursor: pointer;
        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;
        height: 26px;
        border-radius: 29px;
        background: rgba(245, 158, 11, 15%);
        padding: 0 var(--text-distance-2);
        font-size: 12px;
        color: #f59e0b;
        gap: 8px;
        .icon {
          width: 20px;
        }
      }
    }
    .upgrade-account-model {
      .ant-drawer-title {
        font-size: 18px;
      }
      .ant-btn-primary {
        background: var(--primaryButtonBackgroundColor) !important;
        color: var(--primaryButtonTextColor, #ffffff) !important;
      }
    }
  }
}
