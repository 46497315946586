.skeleton-record-list {
  padding: 0 34px;
  padding-top: 0px;
  @media screen and (max-width: 600px) {
    padding: 0 24px;
  }
  :global {
    color: var(--text-color-1);
    .ant-skeleton {
      margin-bottom: 10px;
      .ant-skeleton-header {
        display: flex;
        align-items: center;
        height: 70px;
      }
      .ant-skeleton-paragraph {
        margin-bottom: 10px;
        margin-top: 14px !important;
      }
    }
  }
}
.record-list {
  padding: 0 12px;
  @media screen and (min-width: 600px) {
    padding: 0 24px;
  }
  :global {
    color: var(--text-color-1);
    .item-title {
      font-size: 14px;
      font-weight: 600;
      color: var(--text-color-1);
      margin-bottom: 18px;
      margin-top: 26px;
      &[data-index='0'] {
        margin-top: 0;
      }
    }
    .record-item {
      display: flex;
      justify-content: space-between;
      position: relative;
      background: var(--background-color-1);
      padding: 4px 10px;
      margin-bottom: 10px;
      border-radius: 10px;
      padding-left: 4px;
      padding-right: 14px;
      cursor: pointer;
      .left {
        display: flex;
        align-items: center;
        width: 56px;
        margin-right: 2px;
        .icon {
          width: 56px;
          height: 62px;
          display: flex;
          align-items: center;
          justify-content: center;
          // background-image: url('/images/img_3_1.png');
          // background-repeat: no-repeat;
          // background-size: cover;
          // &.type-1 {
          //   background-image: url('/images/img_3_1.png');
          // }
          // &.type-2 {
          //   background-image: url('/images/img_3_2.png');
          // }
          // &.type-3 {
          //   background-image: url('/images/img_3_3.png');
          // }
          // &.type-4 {
          //   background-image: url('/images/img_3_4.png');
          // }
          // &.type-5 {
          //   background-image: url('/images/img_3_5.png');
          // }
        }
      }
      .right {
        line-height: 1.2;
        text-align: right;
        flex: 1;
        display: flex;
        width: 100%;
        align-items: center;
        .wrap {
          width: 100%;
          .row1 {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            gap: 10px;
            .address {
              font-size: 16px;
              font-weight: 600;
              color: var(--text-color-1);
            }
            .amount {
              font-size: 13px;

              color: #ff7676;
              &.type-1 {
                // background-image: url('/images/img_3_1.png');
                color: #ff7676;
              }
              &.type-2 {
                // background-image: url('/images/img_3_2.png');
                color: var(--message-success-color);
              }
              &.type-3 {
                // background-image: url('/images/img_3_3.png');
                color: #ff7676;
              }
              &.type-4 {
                // background-image: url('/images/img_3_4.png');
                color: #007fff;
              }
              &.type-5 {
                // background-image: url('/images/img_3_5.png');
                color: #ff7676;
              }
            }
          }
          .row2 {
            justify-content: space-between;
            font-size: 12px;
            font-weight: 400;
            color: var(--text-color-2);
            text-align: left;
            position: relative;
            width: 100%;
            display: flex;
            .left {
              width: auto;
              flex: 1;
            }
            .right {
              width: auto;
              flex: initial;
              .pending-btn {
                height: 20px;
                line-height: 20px;
                width: 70px;
                color: var(--accent-color);
                border: 1px solid #007fff;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}
