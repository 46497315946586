.iconWrap {
  width: 40px;
  height: 40px;
  position: relative;
  background: var(--icon-bg-color-1);
  border-radius: 40px;
  box-shadow: 0px 3px 15px 0 var(--shadow-color-3);
  .badge {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    position: absolute;
    bottom: -3px;
    right: -2px;
    border: 2px solid var(--icon-bg-color-1);
    background-color: var(--icon-bg-color-1);
    overflow: hidden;
  }
  :global {
    color: var(--text-color-1);
    .micon {
      position: relative;
      left: -5px;
      top: -4px;
    }
  }
}
