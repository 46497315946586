.tokenIcons {
  height: var(--iconSize, 18px);
  display: flex;
  margin-top: 4px;
  :global {
    .icon_3dd12 {
      height: var(--iconSize, 18px);
      width: calc(var(--iconSize, 18px) * 0.6);
      line-height: 1;
      .ant-image {
        background: var(--content-background-color);
        width: var(--iconSize, 18px);
        height: var(--iconSize, 18px);
        border: calc(var(--iconSize, 18px) * 0.12) solid var(--content-background-color);
        border-radius: 100%;
        .token-icon {
          width: 100% !important;
          height: 100% !important;
          border-radius: 100%;
        }
      }
    }
  }
}
