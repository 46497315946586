.skeleton-record-list {
  padding: 0 34px;
  padding-top: 0px;
  @media screen and (max-width: 600px) {
    padding: 0 24px;
  }
  :global {
    color: var(--text-color-1);
    .ant-skeleton {
      margin-bottom: 10px;
      .ant-skeleton-header {
        display: flex;
        align-items: center;
        height: 70px;
      }
      .ant-skeleton-paragraph {
        margin-bottom: 10px;
        margin-top: 14px !important;
      }
    }
  }
}

.record-list {
  padding: 0 var(--text-distance-1);
  :global {
    color: var(--text-color-1);
    .item-title {
      font-style: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      &.completed {
        margin-top: 20px;
      }
    }
  }
  .record-item {
    display: flex;
    justify-content: space-between;
    position: relative;
    background: var(--background-color-1);
    border-radius: 18px;
    cursor: pointer;
    gap: 10px;
    justify-content: space-between;
    padding: 11px;
    margin-bottom: 11px;
    min-height: 70px;
    @media screen and (min-width: 600px) {
      padding-left: var(--text-distance-2);
      padding-right: var(--text-distance-2);
    }
    :global {
      color: var(--text-color-1);
      .left {
        display: flex;
        gap: 11px;
        font-size: 12px;
        line-height: 1;
        display: flex;
        .col {
          display: flex;
          align-items: center;
        }
        .col2 {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .type {
            font-weight: 600;
            color: var(--text-color-1);
            margin-bottom: 10px;
            word-wrap: break-word;
            white-space: pre-wrap;
            &[data-break-all='true'] {
              word-break: break-all;
            }
          }
          .date {
            color: var(--text-color-2);
          }
          .status {
            color: var(--pending-color);
          }
        }
      }
      .right {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        font-weight: 600;
        line-height: 1;
        width: 58%;
        max-width: 58%;

        .swap-row {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          &.action-out {
            color: #ea4335;
            font-weight: 400;
          }
          &.action-in {
            color: var(--message-success-color);
            font-weight: 400;
          }
        }
        .token-row {
          width: 100%;
          display: flex;
          gap: 3px;
          .token-amount {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            text-align: right;
          }
          .token-symbol {
            display: inline-block;
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
          }
        }
        .row1 {
          color: var(--text-color-1);
          margin-bottom: 9px;
          text-align: right;
        }
        .row2 {
          width: 100%;
          font-weight: 400;
          height: 14px;
          line-height: 1;
          text-align: right;
          &.action-out {
            color: #ea4335;
          }
          &.action-in {
            color: var(--message-success-color);
          }
        }
      }
    }
  }
}
