.toolContainer {
  :global {
    .tools-wrap {
      padding: 0 18px;
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      @media screen and (min-width: 600px) {
        padding: 0 24px;
      }
      .item {
        background: var(--border-color-1);
        width: calc(50% - 10px);
        padding: 14px 14px;
        border-radius: 18px;
        position: relative;
        @media screen and (min-width: 600px) {
          padding: 14px 22px;
        }
        .icon {
          width: 72px;
          height: 72px;
          border-radius: 28px;
          position: absolute;
          left: -10px;
          top: -6px;
          @media screen and (min-width: 600px) {
           left: -2px;
        }
        }
        .title {
          text-align: left;
          font-weight: 600;
          font-size: 12px;
          color: var(--text-color-1);
          margin-top: 35px;
        }
      }
    }
  }
}
