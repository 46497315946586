.container {
  color: var(--text-color-1);
  :global {
    color: var(--text-color-1);
    .ant-skeleton {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
      margin: 0;
    }
    .ant-skeleton-title {
      margin-top: 2px;
      display: none;
    }
    .ant-skeleton-avatar-circle {
      width: 30px;
      height: 30px;
      border-radius: 30px;
    }
    .ant-skeleton-paragraph {
      margin: 0 !important;
      height: 60px;
      position: relative;

      li {
        position: absolute;
        margin: 0;
      }

      li:nth-child(1) {
        width: 54px;
        height: 16px;
        border-radius: 4px;
        top: 14px;
        @media screen and (min-width: 600px) {
          width: 160px;
        }
      }
      li:nth-child(2) {
        width: 100px;
        height: 16px;
        border-radius: 4px;
        top: 20px;
        @media screen and (min-width: 600px) {
          width: 100%;
        }
      }
      li:nth-child(3) {
        width: 70px !important;
        height: 16px;
        border-radius: 4px;
        right: 0;
        top: 20px;
        @media screen and (min-width: 600px) {
          display: none;
        }
      }
    }
  }
}
